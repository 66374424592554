import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import { CircularProgress } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loader: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 100,
    '& > div': {
      position: 'relative',
      top: '50%',
      left: '50%',
      marginTop: '-20px',
      marginLeft: '-20px',
    },
  },
}));

export default function CustomDialog({ loading, children, ...props }) {
  const classes = useStyles();

  return (
    <Dialog {...props}>
      { children }

      { loading ? (
        <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      ) : null }
    </Dialog>
  );
}

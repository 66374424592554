import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CustomInput from 'components/CustomInput/CustomInput';
import Button from 'components/CustomButtons/Button';
import logo from 'assets/img/apple-icon.png';
import {
  transition,
  container, hexToRgb, grayColor,
} from 'assets/jss/material-dashboard-react';

import { login } from 'services/session';
import CardBody from '../../components/Card/CardBody';
import CardFooter from '../../components/Card/CardFooter';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
  },
  mainPanel: {
    overflow: 'auto',
    position: 'relative',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '70px',
    padding: '30px 15px',
    minHeight: 'calc(100vh - 123px)',
  },
  container: {
    ...container,
    ...{
      maxWidth: '400px',
      margin: '0 auto',
    },
  },
  cardFooter: {
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  logo: {
    position: 'relative',
    padding: '15px 15px',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: `rgba(${hexToRgb(grayColor[6])}, 0.3)`,
    },
  },
  img: {
    width: '35px',
    top: '16px',
    position: 'absolute',
    verticalAlign: 'middle',
    border: '0',
  },
  logoImage: {
    width: '30px',
    display: 'inline-block',
    maxHeight: '30px',
    marginLeft: '10px',
    marginRight: '15px',
  },
  loginTitle: {
    display: 'inline-block',
    lineHeight: '45px',
    marginLeft: '10px',
    marginTop: '0',
  },
}));

export default function Login() {
  document.title = 'Login | Fluvius';

  // styles
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const [username, setUsername] = useState('');

  const [password, setPassword] = useState('');

  const [redirectUrl, setRedirectUrl] = useState('');

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    try {
      await login(username, password);

      setRedirectUrl('/');
    } catch (error) {
      setErrorMessage(error.message || 'Ошибка входа');
    }

    setLoading(false);
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanel}>
        <form className={classes.container} onSubmit={handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardBody>

                  <div className={classes.logoImage}>
                    <img
                      src={logo}
                      alt="logo"
                      className={classes.img}
                    />
                  </div>
                  <h4 className={classes.loginTitle}>Fluvius</h4>

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Login"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          onChange: (e) => setUsername(e.target.value),
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Password"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          type: 'password',
                          onChange: (e) => setPassword(e.target.value),
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter className={classes.cardFooter}>
                  <Button color="primary" type="submit">Send</Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </form>
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={!!errorMessage}
          autoHideDuration={3000}
          onClose={() => setErrorMessage('')}
        >
          <Alert elevation={6} variant="filled" severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      </div>
      { redirectUrl ? <Redirect push to={redirectUrl} /> : ''}
    </div>
  );
}

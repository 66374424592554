import { formatOptionItem } from './text';

const DEFAULT_CURRENCY = 'USD';

const splitNumberOnThousands = (number) => {
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const formatMoney = (
  value,
  currency = DEFAULT_CURRENCY,
  fractionDigits = 2,
) => {
  let multiplier = 1;
  for (let i = 0; i < fractionDigits; i++) {
    multiplier *= 10;
  }

  value = (Math.round(value * multiplier) / multiplier).toFixed(fractionDigits);

  value = splitNumberOnThousands(value);

  return `${value}${currency ? ` ${currency}` : ''}`;
};

export const formatNumber = (number) => Math.round(number * 100) / 100;

export const getDefaultCurrency = () => DEFAULT_CURRENCY;

export function getContractTypes() {
  return [
    { id: 'monthly', name: 'Monthly' },
    { id: 'hourly', name: 'Hourly' },
  ];
}

export function formatContractType(contractType) {
  return formatOptionItem(contractType, getContractTypes());
}

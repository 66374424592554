import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components

import moment from 'moment';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import { ConsoleContext } from 'services/context';
import Icon from '@material-ui/core/Icon';
import CustomSelect from '../CustomSelect/CustomSelect';

export default function MonthPicker({
  onChange, storage,
}) {
  const { t } = useContext(ConsoleContext);

  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [options, setOptions] = useState([]);

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {
    let year = moment().year();
    let month = moment().month();

    if (storage) {
      year = storage.getValue('year', year);
      month = storage.getValue('month', month);
    }

    const years = [year - 1, year, year + 1];

    const options = [];

    years.forEach((year) => {
      months.forEach((month, monthNumber) => {
        options.push({
          id: `${year}-${monthNumber}`,
          name: `${t(month)} ${year}`,
        });
      });
    });

    setOptions(options);

    setYear(year);
    setMonth(month);
  }, []);

  useEffect(() => {
    if (onChange && year) {
      onChange({ year, month });
    }
  }, [year, month]);

  const handleChange = async (value) => {
    if (!value) {
      return;
    }

    const [year, month] = value.split('-').map((value) => Number(value));

    setYear(year);
    setMonth(month);

    if (storage) {
      storage.setValue('year', year);
      storage.setValue('month', month);
    }
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <CustomSelect
          required
          value={`${year}-${month}`}
          options={options}
          onChange={handleChange}
          icon={(
            <Icon style={{ marginLeft: '15px' }}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M12.6667 3.16666H3.33333C2.59695 3.16666 2 3.76362 2 4.5V13.8333C2 14.5697
                2.59695 15.1667 3.33333 15.1667H12.6667C13.403 15.1667 14 14.5697 14 13.8333V4.5C14 3.76362 13.403 3.16666 12.6667 3.16666Z"
                  stroke="#4C5263"
                  strokeWidth="1.33"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path d="M10.6667 1.83334V4.5" stroke="#4C5263" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.33333 1.83334V4.5" stroke="#4C5263" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2 7.16666H14" stroke="#4C5263" strokeWidth="1.33" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Icon>
          )}
        />
      </GridItem>
    </GridContainer>
  );
}

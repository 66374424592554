import React, { useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import {
  formatStatus,
} from 'services/invoice';

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles(() => ({
  alert: {
    display: 'inline-block',

    '& .MuiAlert-message': {
      padding: 0,
    },
  },
}));

export default function InvoiceStatus({ status }) {
  const classes = useStyles();

  const { t } = useContext(ConsoleContext);

  const colors = {
    created: 'info',
    sent: 'warning',
    completed: 'success',
    rejected: 'error',
  };

  const label = formatStatus(status);

  return (
    <Alert
      icon={false}
      color={colors[status]}
      className={classes.alert}
    >
      {t(label)}
    </Alert>
  );
}

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import {
  Router, Route, Switch,
} from 'react-router-dom';

// core components
import './services/locale';

import Console from './layouts/Console';
import Login from './views/Login/Login';

import 'assets/css/material-dashboard-react.css';

const hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/" component={Console} />
      </Switch>
    </Suspense>
  </Router>,
  document.getElementById('root'),
);

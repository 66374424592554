import {
  USER_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export async function getUsers() {
  return get(USER_URL);
}

export function formatUserName(user) {
  if (!user) {
    return '-';
  }

  return `${user.firstName} ${user.lastName}`;
}

import React, { useContext, useEffect, useState } from 'react';
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from '@material-ui/core/styles';
// @material-ui/icons
import CloudDownload from '@material-ui/icons/CloudDownload';
import Sync from '@material-ui/icons/Sync';
// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';

import styles from 'assets/jss/material-dashboard-react/views/dashboardStyle';
import Icon from '@material-ui/core/Icon';
import { formatMoney } from 'services/finance';
import getDashboardMetrics from 'services/dashboard';
import { ConsoleContext } from 'services/context';
import MonthPicker from 'components/MonthPicker/MonthPicker';
import CardBody from 'components/Card/CardBody';
import { formatUserName, getUsers } from 'services/user';
import getRoles from 'services/role';
import { getProjectMonthlySummary, getProjects } from 'services/project';
import ProjectLogsExport from './components/ProjectLogsExport';
import LogsSync from './components/LogsSync';
import ProjectReport from './components/Report';

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  const {
    showError, showLoader, getSessionStorage, t, showPageTitle,
  } = useContext(ConsoleContext);

  const [projects, setProjects] = useState([]);

  const [users, setUsers] = useState([]);

  const [members, setMembers] = useState([]);

  const [membersInfo, setMembersInfo] = useState([]);

  const [projectsVisible, setProjectsVisible] = useState({});

  const [roles, setRoles] = useState([]);

  const [projectsSummary, setProjectsSummary] = useState({});

  const [dashboardReport, setDashboardReport] = useState({
    usersEquivalent: 0,
    currentMonthlyBudget: 0,
  });

  const [date, setDate] = useState({});

  const storage = getSessionStorage();

  showPageTitle('Dashboard');

  useEffect(() => {
    (async () => {
      showLoader(true);
      const projects = await getProjects();
      const users = await getUsers();
      const roles = await getRoles({ circleId: 'production' });
      setUsers(users);
      setRoles(roles);
      setProjects(projects);
      showLoader(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { year } = date;

      if (!year) {
        return;
      }
      showLoader(true);
      try {
        const membersList = [];
        const projectsObject = {};
        if (projects.length) {
          const projectsSummary = {};
          await Promise.all(projects.map(async (project) => {
            if (project.members.length) {
              const summary = await getProjectMonthlySummary(project._id, date.year, date.month);
              projectsSummary[project._id] = summary;

              project.members.forEach((member) => {
                const hasPlanOrFactInSummary = Boolean(summary.find((data) => data.userId === member.userId._id
                    && (data.logBillableHours > 0 || data.planBillableHours > 0)));

                if (hasPlanOrFactInSummary && !checkMemberInList(member, membersList)) {
                  membersList.push(member);

                  projectsObject[project._id] = true;
                }
              });
            }
          }));

          setProjectsSummary(projectsSummary);
          setMembers(membersList);
          setProjectsVisible(projectsObject);
        }

        const dashboardReport = await getDashboardMetrics(date.year, date.month);
        setDashboardReport(dashboardReport);
      } catch (error) {
        showError(error);
      }
      showLoader(false);
    })();
  }, [date, projects]);

  useEffect(() => {
    if (users.length && members.length) {
      (async () => {
        const membersInfo = members.map((member) => {
          const memberRoles = member.roleIds.map((roleId) => roles.find((role) => role._id === roleId._id));
          return {
            id: member._id,
            memberId: member.userId._id,
            name: formatMemberName(member),
            roles: memberRoles.map((role) => role.name).join(', '),
          };
        });
        setMembersInfo(membersInfo);
      })();
    }
  }, [members, users]);

  function formatMemberName(member) {
    const user = users.find((user) => user._id === member.userId._id);

    return formatUserName(user);
  }

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  function checkMemberInList(member, list) {
    return Boolean(list.find((item) => item.userId._id === member.userId._id));
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={2}>
          <MonthPicker
            storage={storage}
            onChange={handleMonthChange}
          />
        </GridItem>
      </GridContainer>
      { projects.length ? (
        <>
          <GridContainer>

            <GridItem xs={12} sm={6} md={3}>
              <Card className={classes.cardBackground}>
                <CardHeader>
                  <div className={classes.cardBlock}>
                    <Icon className={classes.cardIcon}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M17 21V19C17 17.9391 16.5786 16.9217 15.8284 16.1716C15.0783
                          15.4214 14.0609 15 13 15H5C3.93913 15 2.92172 15.4214 2.17157 16.1716C1.42143 16.9217 1 17.9391 1 19V21"
                          stroke="#11AEAC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M9 11C11.2091 11 13 9.20914 13 7C13 4.79086 11.2091
                          3 9 3C6.79086 3 5 4.79086 5 7C5 9.20914 6.79086 11 9 11Z"
                          stroke="#11AEAC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M23 21V19C22.9993 18.1137 22.7044 17.2528 22.1614
                          16.5523C21.6184 15.8519 20.8581 15.3516 20 15.13"
                          stroke="#11AEAC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M16 3.13C16.8604 3.35031 17.623 3.85071 18.1676 4.55232C18.7122 5.25392 19.0078 6.11683 19.0078
                          7.005C19.0078 7.89318 18.7122 8.75608 18.1676 9.45769C17.623 10.1593 16.8604 10.6597 16 10.88"
                          stroke="#11AEAC"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                    </Icon>
                    <h3 className={classes.cardTitle}>{ dashboardReport.usersEquivalent }</h3>
                  </div>
                  <p className={classes.cardCategory}>Full Time Equivalent</p>
                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <Card className={classes.cardBackground}>
                <CardHeader>
                  <div className={classes.cardBlock}>
                    <h3 className={classes.cardTitle}>
                      $
                      { formatMoney(dashboardReport.currentMonthlyBudget, false, 0)}
                    </h3>
                  </div>
                  <p className={classes.cardCategory}>Current Monthly Budget </p>

                </CardHeader>
              </Card>
            </GridItem>

            <GridItem xs={12} sm={6} md={3}>
              <Card className={classes.cardBackground}>
                <CardHeader stats icon>

                  <div className={classes.cardBlock}>
                    <CloudDownload htmlColor="#11AEAC" />

                    <div>
                      { projects.map((project) => (

                        <div key={project._id}>
                          {membersInfo.length && projectsVisible[project._id] ? (
                            <div className={classes.cardCategory}>
                              <span style={{
                                marginRight: '10px',
                                fontSize: '12px',
                                fontFamily: 'Roboto',
                                fontWeight: '400',
                              }}
                              >
                                {project.name}
                              </span>

                              <ProjectLogsExport
                                project={project}
                                date={date}
                              />

                            </div>
                          ) : null }
                        </div>

                      )) }
                    </div>

                  </div>

                </CardHeader>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>

              <Card className={classes.cardBackground}>
                <CardHeader stats icon>

                  <div className={classes.cardBlock}>
                    <Sync htmlColor="#11AEAC" />

                    <div>
                      <LogsSync year={date.year} month={date.month} />
                    </div>

                  </div>

                </CardHeader>
              </Card>

            </GridItem>
          </GridContainer>

          { membersInfo.length && projectsSummary && dashboardReport.report && dashboardReport.report.summary.length
            ? (
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardHeader color="info">
                      <h4>Report</h4>
                    </CardHeader>
                    <CardBody>
                      {projects.map((project) => (
                        <div key={project._id}>
                          <div className={classes.cardCategory}>
                            <ProjectReport
                              project={project}
                              membersInfo={membersInfo}
                              date={date}
                              report={dashboardReport.report}
                              projectSummary={projectsSummary[project._id]}
                            />
                            <br />
                          </div>
                        </div>
                      )) }
                    </CardBody>
                  </Card>
                </GridItem>
              </GridContainer>
            )
            : (
              <>
                {t('No projects')}
                {' '}
                :(
              </>
            )}
        </>
      ) : null }
    </div>
  );
}

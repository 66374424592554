// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DateRangeIcon from '@material-ui/icons/DateRange';

// core components/views for Admin layout
import DashboardPage from 'views/Dashboard/Dashboard';
import Invoice from 'views/Invoice/Invoice';
import ClientContractDetails from 'views/ClientContract/ClientContractDetails';
import WorkCalendar from 'views/WorkCalendar/WorkCalendar';

const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: Dashboard,
    component: DashboardPage,
    layout: '',
  },
  {
    path: '/invoices',
    name: 'Invoices',
    icon: AttachMoneyIcon,
    component: Invoice,
    layout: '',
  },
  {
    path: '/contracts',
    name: 'Contracts',
    icon: FolderOpenIcon,
    component: ClientContractDetails,
    layout: '',
  },
  {
    path: '/work-calendar',
    name: 'Work Calendar',
    icon: DateRangeIcon,
    component: WorkCalendar,
    layout: '',
  },

];

export default dashboardRoutes;

import {
  CLIENT_CONTRACT_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export default async function getClientContracts() {
  return get(CLIENT_CONTRACT_URL);
}

import React, {
  useState, useEffect, useContext, Fragment, useRef,
} from 'react';

import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import {
  getProjectMonthlySummary,
} from 'services/project';

import { ConsoleContext } from 'services/context';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatNumber } from 'services/finance';
import ProjectLogsDialog from './ProjectLogsDialog';
import ProjectLogsExport from './ProjectLogsExport';

const useStyles = makeStyles(() => ({

  projectLogTable: {
    '& td': {
      borderTop: '1px solid #E1E7EF',
    },
    '& td , & th': {
      textAlign: 'center',
    },
    '& tbody': {
      '& tr': {
        '&:last-child': {
          borderBottom: 'none!important',
        },
      },
    },
  },
  styleGreyTh: {
    '& th': {
      fontSize: '14px!important',
      color: '#A7BECC!important',
      borderBottom: '1px solid #2C4859!important',
      borderColor: '#2C4859!important',
    },
  },
  styleProjectTh: {
    '& th': {
      fontWeight: '700',
      fontSize: '17px!important',
      borderTop: 'none!important',
    },
  },
  styleNoBorderBottom: {
    borderBottom: 'none!important',
  },
  styleCell: {
    '& td': {
      fontWeight: '700',
      fontSize: '17px!important',
    },
  },
  colorBlue: {
    color: '#6585DB',
  },
  cellLog: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  styleNoPaddingRight: {
    paddingRight: '0!important',
  },
  styleNoPaddingLeft: {
    paddingLeft: '0!important',
  },
  styleNoPadding: {
    padding: '0!important',
  },
  tableBorderRight: {
    borderRight: '1px solid #2C4859!important',
  },
  styleAlignLeft: {
    textAlign: 'left !important',
  },
  timesheetToday: {
    backgroundColor: '#11b5c9',
  },
}));

export default function ProjectLogs({
  project, formatMemberName, members, date,
}) {
  const classes = useStyles();

  const { showError, showLoader } = useContext(ConsoleContext);

  const [summary, setSummary] = useState();

  const [openLogsDialog, setOpenLogsDialog] = useState(false);

  const [activeDate, setActiveDate] = useState({
    date: null,
    userId: null,
    billable: null,
  });

  const [days, setDays] = useState([]);

  const [total, setTotal] = useState({});

  const tableRef = useRef(null);

  useEffect(() => {
    const { year, month } = date;

    if (!year) {
      return;
    }

    const startDate = moment.utc(`${year} ${month + 1} 1`, 'YYYY M D');
    const endDate = moment(startDate).endOf('month');

    const days = [];

    for (let d = moment(startDate); d < endDate; d.add(1, 'days')) {
      days.push({
        date: moment(d),
        name: d.format('ddd D'),
      });
    }

    setDays(days);

    async function fetchData() {
      showLoader(true);
      try {
        const summary = await getProjectMonthlySummary(project._id, year, month);

        const groupedSummary = {};

        summary.forEach((data) => {
          const { userId } = data;
          const date = moment.utc(data.date).format('D');

          if (!groupedSummary[userId]) {
            groupedSummary[userId] = {};
          }

          groupedSummary[userId][date] = data;
        });

        setSummary(groupedSummary);
        updateTotal(groupedSummary);
      } catch (e) {
        showError(e);
      }
      showLoader(false);
    }

    fetchData();
  }, [date, members.length, project]);

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }

    const today = moment();

    const currentDate = moment(`${date.year}-${date.month + 1}`, 'YYYY-MM');

    if (!currentDate.isSame(today, 'month')) {
      tableRef.current.scrollLeft = 0;
      return;
    }

    const dayIndex = today.format('D') - 1;

    const width = tableRef.current.offsetWidth;
    const left = dayIndex * 150 - Math.round(width / 2) + 75;

    tableRef.current.scrollLeft = left;
  }, [date, tableRef.current]);

  const handleShowLogs = async (userId, date, overtimeValue) => {
    setActiveDate({
      projectId: project._id, date, userId, overtimeValue,
    });
    setOpenLogsDialog(true);
  };

  const handleCloseLogsDialog = () => {
    setOpenLogsDialog(false);
  };

  const updateTotal = (summary) => {
    const total = {};

    Object.keys(summary).forEach((userId) => {
      if (!total[userId]) {
        total[userId] = {
          planBillable: 0,
          factBillable: 0,
          overtimeBillableX10: 0,
          overtimeBillableX15: 0,
          overtimeBillableX20: 0,
        };
      }

      const userTotal = total[userId];

      Object.keys(summary[userId]).forEach((date) => {
        const summaryDay = summary[userId][date];

        userTotal.planBillable += summaryDay.planBillableHours || 0;
        userTotal.factBillable += summaryDay.logBillableHours || 0;

        if (summaryDay.overtimeBillableHours) {
          if (summaryDay.overtimeBillableRateByClient === 1) {
            userTotal.overtimeBillableX10 += 0;
          }
          if (summaryDay.overtimeBillableRateByClient === 1.5) {
            userTotal.overtimeBillableX15 += summaryDay.overtimeBillableHours || 0;
          }
          if (summaryDay.overtimeBillableRateByClient === 2) {
            userTotal.overtimeBillableX20 += summaryDay.overtimeBillableHours || 0;
          }
        }
      });
    });

    setTotal(total);
  };

  const renderTimeCell = (userId, date, type) => {
    const day = date.format('D');

    if (!summary[userId] || !summary[userId][day]
        || (!summary[userId][day].logBillableHours && !summary[userId][day].planBillableHours && type === 'plan')) {
      return (
        <span>-</span>
      );
    }

    const summaryDay = summary[userId][day];

    const { holiday } = summaryDay;

    let value;
    let title = '';
    let className;
    let onClick = () => {};

    switch (type) {
      case 'plan': {
        if (holiday) {
          value = '';
        } else {
          value = summaryDay.planBillableHours || 0;

          value = value ? (<>{value}</>) : 0;
        }

        className = `${classes.cellPlan} ${classes.colorBlue}`;
        break;
      }

      case 'log': {
        className = '';

        value = summaryDay.logBillableHours || 0;

        let overtimeValue = 0;
        if (summaryDay.overtimeBillableRateByClient !== 1) {
          overtimeValue = summaryDay.overtimeBillableHours;
        }
        if (holiday && !value && !overtimeValue) {
          value = '';
        }

        if (value || overtimeValue) {
          title = 'Click to view logs';
          className = `${classes.cellLog}`;

          value = (
            <>
              {' / '}
              {value}
              {overtimeValue ? (
                <span style={{ color: 'red' }}>
                  {` (${overtimeValue})`}
                </span>
              ) : null}
            </>
          );
          onClick = () => handleShowLogs(userId, date, overtimeValue);
        }

        if (value === 0) {
          value = ' / 0';
        }
        break;
      }

      default:
        break;
    }

    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <span
        className={className}
        title={title}
        onClick={onClick}
      >
        {value}
      </span>
    );
  };

  const checkCurrentDay = (day) => moment(day.date).isSame(moment(), 'day');

  const membersList = [];

  if (summary && Object.keys(summary).length && members.length) {
    members.forEach((data) => {
      const userId = data.userId._id;
      if (summary[userId]) {
        const userHasPlanOrFactHours = Boolean(Object.values(summary[userId])
          .find((item) => item.planBillableHours > 0 || item.logBillableHours > 0));

        if (userHasPlanOrFactHours) {
          membersList.push(data);
        }
      }
    });
  }

  return (
    <>
      <br />
      { summary && Object.keys(summary).length && membersList.length ? (
        <GridContainer>
          <GridItem className={classes.styleNoPaddingRight} xs={12} sm={12} md={5}>
            <TableContainer className={classes.projectLogTable}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.styleProjectTh}>
                    <TableCell width="180px" className={classes.styleAlignLeft}>{project.name}</TableCell>
                    <TableCell colSpan={2}>Plan / Fact</TableCell>
                    <TableCell colSpan={2}>Overtime</TableCell>
                  </TableRow>
                  <TableRow className={classes.styleGreyTh}>
                    <TableCell
                      className={`${classes.tableBorderRight} ${classes.styleAlignLeft}`}
                    >
                      Member
                    </TableCell>
                    <TableCell> Plan</TableCell>
                    <TableCell className={classes.tableBorderRight}>Fact</TableCell>
                    <TableCell>x2</TableCell>
                    <TableCell className={` ${classes.tableBorderRight}`}>x1.5</TableCell>
                  </TableRow>

                </TableHead>
                <TableBody>
                  { membersList.map((member) => (
                    <TableRow className={classes.styleCell} key={member.userId._id}>
                      <TableCell
                        className={`${classes.tableBorderRight} ${classes.styleAlignLeft}`}
                        title={formatMemberName(member)}
                        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                      >
                        {formatMemberName(member)}
                      </TableCell>
                      { total[member.userId._id] ? (
                        <>
                          <TableCell>
                            {total[member.userId._id].planBillable}
                          </TableCell>

                          <TableCell className={classes.tableBorderRight}>
                            {formatNumber(total[member.userId._id].factBillable)}
                          </TableCell>

                          <TableCell>
                            {total[member.userId._id].overtimeBillableX20}
                          </TableCell>

                          <TableCell className={classes.tableBorderRight}>
                            {total[member.userId._id].overtimeBillableX15}
                          </TableCell>

                        </>
                      ) : (<TableCell colSpan={5}>&nbsp;</TableCell>)}
                    </TableRow>
                  ))}
                  <TableRow className={classes.styleNoBorderBottom}>
                    <TableCell colSpan={5} className={classes.styleAlignLeft}>
                      <ProjectLogsExport
                        project={project}
                        date={date}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>

          <GridItem className={classes.styleNoPaddingLeft} xs={12} sm={12} md={7}>
            <TableContainer className={classes.projectLogTable}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.styleProjectTh}>
                    <TableCell
                      className={`${classes.styleAlignLeft}`}
                      colSpan={days.length * 2}
                      width={`${100 * days.length}px`}
                    >
                      <span style={{ opacity: '0' }}>Daily</span>
                      <span style={{ position: 'absolute' }}>Daily Plan / Fact</span>
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.styleGreyTh}>
                    {days.map((day) => (
                      <TableCell
                        key={day.name}
                        colSpan={2}
                        className={checkCurrentDay(day) ? `${classes.timesheetToday}` : ''}
                      >
                        {day.name}
                      </TableCell>
                    ))}

                  </TableRow>

                </TableHead>
                <TableBody>
                  { membersList.map((member) => (
                    <TableRow className={classes.styleCell} key={member.userId._id}>
                      {days.map((day) => (
                        <Fragment key={day.name}>
                          <TableCell colSpan={2}>
                            {renderTimeCell(member.userId._id, day.date, 'plan')}
                            {renderTimeCell(member.userId._id, day.date, 'log')}
                          </TableCell>
                        </Fragment>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell className={classes.styleNoPadding} colSpan={days.length * 2} />
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      ) : null}

      {membersList.length && activeDate.userId
        ? (
          <ProjectLogsDialog
            open={openLogsDialog}
            formatMemberName={formatMemberName}
            {...activeDate}
            onClose={handleCloseLogsDialog}
          />
        )
        : null}
    </>
  );
}

class SessionStorage {
  constructor(section = 'global') {
    this.section = section;
  }

  getValue(key, defaultValue) {
    const value = sessionStorage.getItem(this.getSectionKey(key));

    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return defaultValue;
      }
    }

    return defaultValue;
  }

  setValue(key, value) {
    sessionStorage.setItem(this.getSectionKey(key), JSON.stringify(value));
  }

  getSectionKey(key) {
    return `${this.section}.${key}`;
  }
}

class LocalStorage {
  constructor(section = 'global') {
    this.section = section;
  }

  getValue(key, defaultValue) {
    const value = localStorage.getItem(this.getSectionKey(key));

    if (value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return defaultValue;
      }
    }

    return defaultValue;
  }

  setValue(key, value) {
    localStorage.setItem(this.getSectionKey(key), JSON.stringify(value));
  }

  getSectionKey(key) {
    return `${this.section}.${key}`;
  }
}

export function getSessionStorage(section) {
  return new SessionStorage(section);
}

export function getLocalStorage(section) {
  return new LocalStorage(section);
}

import {
  JIRA_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export default async function getLastSyncJob(year, month) {
  return get(`${JIRA_URL}/last-sync-job/${year}/${month}`);
}

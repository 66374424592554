import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/CustomButtons/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowNextIcon from '@material-ui/icons/ArrowForward';

export default function ArrowButton({ to, children, next }) {
  return (
    <Link to={to}>
      <Button
        size="sm"
        startIcon={next ? null : (<ArrowBackIcon fontSize="small" />)}
        endIcon={next ? (<ArrowNextIcon fontSize="small" />) : null}
        style={{ lineHeight: '100%' }}
      >
        {children}
      </Button>
    </Link>
  );
}

import {
  blackColor,
  whiteColor,
  hexToRgb,
} from 'assets/jss/material-dashboard-react';

const cardStyle = {
  card: {
    marginBottom: '30px',
    marginTop: '30px',
    marginLeft: '0',
    marginRight: '15px',
    color: `rgba(${hexToRgb(blackColor)}, 0.87)`,
    background: whiteColor,
    width: '100%',
    border: `1px solid #8AA1AF`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: '.875rem',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center',
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px',
    },
  },
};

export default cardStyle;

import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from 'clsx';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Tooltip from '@material-ui/core/Tooltip';
// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks';
import RTLNavbarLinks from 'components/Navbars/RTLNavbarLinks';

import styles from 'assets/jss/material-dashboard-react/components/sidebarStyle';

import { can } from '../../services/permission';

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const {
    color, logo, image, logoText, extendedSidebar, onSaveExtended, rtlActive, open: mobileOpen, handleDrawerToggle,
  } = props;
  const [open, setOpen] = useState(extendedSidebar);

  useEffect(() => {
    async function checkPermissions() {
      const { routes } = props;

      await Promise.all(routes.map(async (route) => {
        if (route.can) {
          const allowed = await can(route.can);

          if (!allowed) {
            route.hidden = true;
          }
        }
      }));

      setRoutes(routes);
    }

    checkPermissions();
  }, []);

  const [routes, setRoutes] = useState([]);

  const classes = useStyles();
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1;
  }

  const getLinks = (isMobile) => (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.hidden) {
          return '';
        }

        const listItemClasses = classNames({
          [` ${classes[color]}`]: activeRoute(prop.layout + prop.path),
        });

        const whiteFontClasses = classNames({
          [` ${classes.whiteFont}`]: activeRoute(prop.layout + prop.path),
        });

        let iconAndTooltipAndText;
        if (open || isMobile) {
          iconAndTooltipAndText = (
            <>
              <prop.icon
                className={classNames(classes.itemIcon, classes.greenFront, {
                  [classes.itemIconRTL]: rtlActive,
                })}
              />
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                className={classNames(classes.itemText, whiteFontClasses, {
                  [classes.itemTextRTL]: rtlActive,
                })}
                disableTypography
              />
            </>
          );
        } else {
          iconAndTooltipAndText = (
            <Tooltip title={open && rtlActive ? prop.rtlName : prop.name} placement="top">
              <prop.icon
                className={classNames(classes.itemIcon, classes.greenFront, {
                  [classes.itemIconRTL]: rtlActive,
                })}
              />
            </Tooltip>
          );
        }
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={classes.item}
            activeClassName="active"
            key={key}
          >
            <ListItem
              button
              className={clsx({
                [classes.itemLink + listItemClasses]: open,
                [classes.itemLinkClose + listItemClasses]: !open,
              })}
            >

              {typeof prop.icon === 'string' ? (
                <Icon
                  className={classNames(classes.itemIcon, classes.greenFront, {
                    [classes.itemIconRTL]: rtlActive,
                  })}
                >
                  {prop.icon}
                </Icon>
              )
                : iconAndTooltipAndText}
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );
  const getBrand = (isMobile) => (
    <div className={clsx({
      [classes.logo]: open,
      [classes.logoClose]: !open,
    })}
    >

      <a
        href="/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: rtlActive,
        })}
      >
        <div className={clsx({
          [classes.logoImage]: open,
          [classes.logoImageClose]: !open,
        })}
        >
          <img
            src={logo}
            alt="logo"
            className={clsx({
              [classes.img]: open,
              [classes.imgClose]: !open,
            })}
          />
        </div>
        {open || isMobile ? logoText : ''}
      </a>
    </div>
  );

  const handleDrawerOpen = () => {
    setOpen(true);
    onSaveExtended(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    onSaveExtended(false);
  };

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={rtlActive ? 'left' : 'right'}
          open={mobileOpen}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: rtlActive,
            }),
          }}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {getBrand(true)}
          <div className={classes.sidebarWrapper}>
            {rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {getLinks(true)}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>

      <Hidden smDown implementation="css">
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          {getBrand(false)}
          <div
            className={clsx({
              [classes.sidebarWrapper]: open,
              [classes.sidebarWrapperClose]: !open,
            })}
          >
            {getLinks(false)}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
        <IconButton
          className={clsx({
            [classes.arrowNone]: !open,
            [classes.arrowLeft]: open,
          })}
          onClick={handleDrawerClose}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton, {
            [classes.arrowNone]: open,
            [classes.burgerLeft]: !open,
          })}
        >
          <ChevronRightIcon />
        </IconButton>
      </Hidden>
    </div>
  );
}

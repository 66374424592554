import React, {
  useState, useEffect,
} from 'react';

import moment from 'moment';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatMoney, formatNumber } from 'services/finance';

const useStyles = makeStyles(() => ({
  timesheetTable: {
    '& td , & th': {
      textAlign: 'center',
    },
    '& tbody': {
      '& tr': {
        '&:last-child': {
          borderBottom: '1px solid #8AA1AF!important',
        },
      },
    },
  },
  styleCellAndBorderColorDark: {
    '& td , & th': {
      fontSize: '13px!important',
      color: '#4C5263',
      borderColor: '#8AA1AF!important',
    },
  },
  styleCellHours: {
    '& td , & th': {
      fontSize: '13px!important',
      color: '#4C5263',
    },
  },
  styleCellHoursAndBorderColorDark: {
    '& td': {
      fontWeight: '400',
      fontSize: '13px!important',
      color: '#4C5263',
      borderColor: '#8AA1AF!important',
    },
  },
  styleBorderBottom: {
    '& th': {
      borderBottom: '1px solid #8AA1AF!important',
    },
  },
  cellLog: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
  borderColorDark: {
    borderColor: '#8AA1AF!important',
  },
  separator: {
    float: 'left',
    height: '30px',
    width: '1px',
    background: '#8AA1AF',
  },
  styleAlignLeft: {
    textAlign: 'left !important',
  },
  styleLineHeight: {
    lineHeight: '15px!important',
  },
  styleFontSize: {
    '& th': {
      fontSize: '17px!important',
    },
  },
}));

export default function ProjectReport({
  project, membersInfo, date, report, projectSummary,
}) {
  const classes = useStyles();

  const [summary, setSummary] = useState();

  const [members, setMembers] = useState([]);

  const [total, setTotal] = useState({});

  const getCostPlanByUserId = (userId) => report.summary.find((data) => data.userId === userId);

  const updateTotal = (summary, members) => {
    const total = {};

    Object.keys(summary).forEach((userId) => {
      if (members.length && members.find((member) => userId === member.userId._id)) {
        if (!total[userId]) {
          total[userId] = {
            planBillable: 0,
            factBillable: 0,
            overtimeBillableX10: 0,
            overtimeBillableX15: 0,
            overtimeBillableX20: 0,
          };
        }

        const userTotal = total[userId];
        const costsByUserId = getCostPlanByUserId(userId);
        userTotal.planCosts = formatMoney(costsByUserId.planCosts, false);
        userTotal.factCosts = formatMoney(costsByUserId.factCosts, false);
        userTotal.factOvertimeCosts = formatMoney(costsByUserId.factOvertimeCosts, false);
        userTotal.overtimeHours = 0;

        Object.keys(summary[userId]).forEach((date) => {
          const summaryDay = summary[userId][date];

          userTotal.planBillable += summaryDay.planBillableHours || 0;
          userTotal.factBillable += summaryDay.logBillableHours || 0;

          if (summaryDay.overtimeBillableHours) {
            if (summaryDay.overtimeBillableRateByClient === 1) {
              userTotal.overtimeHours += 0;
            }
            if (summaryDay.overtimeBillableRateByClient === 1.5) {
              userTotal.overtimeHours += summaryDay.overtimeBillableHours * 1.5 || 0;
            }
            if (summaryDay.overtimeBillableRateByClient === 2) {
              userTotal.overtimeHours += summaryDay.overtimeBillableHours * 2 || 0;
            }
          }
        });
      }
    });

    let planBillable = 0;
    let factBillable = 0;
    let overtime = 0;
    let planCosts = 0;
    let factCosts = 0;
    let factOvertimeCosts = 0;

    Object.keys(total).forEach((userId) => {
      if (members.length && members.find((member) => userId === member.userId._id)) {
        planBillable += total[userId].planBillable;
        factBillable += total[userId].factBillable;
        overtime += total[userId].overtimeHours;
      }
    });

    members.forEach((member) => {
      report.summary.forEach((data) => {
        if (data.userId === member.userId._id) {
          planCosts += data.planCosts;
          factCosts += data.factCosts;
          factOvertimeCosts += data.factOvertimeCosts;
        }
      });
    });

    total.planCosts = formatMoney(planCosts, false);
    total.factCosts = formatMoney(factCosts, false);
    total.factOvertimeCosts = formatMoney(factOvertimeCosts, false);
    total.planBillable = formatNumber(planBillable);
    total.factBillable = formatNumber(factBillable);
    total.overtime = formatNumber(overtime);
    setTotal(total);
  };

  useEffect(() => {
    const { year } = date;

    if (!year) {
      return;
    }

    const groupedSummary = {};

    const { members } = project;
    let membersList = [];

    membersList = members.filter((member) => projectSummary.find((data) => (data.userId === member.userId._id)
          && (data.logBillableHours > 0 || data.planBillableHours > 0)));

    setMembers(membersList);

    projectSummary.forEach((data) => {
      const { userId } = data;
      const date = moment.utc(data.date).format('D');

      if (!groupedSummary[userId]) {
        groupedSummary[userId] = {};
      }

      groupedSummary[userId][date] = data;
    });

    setSummary(groupedSummary);
    updateTotal(groupedSummary, membersList);
  }, [date, project, report]);

  const formatMember = (userId) => {
    const memberData = membersInfo.find((member) => member.memberId === userId);
    if (memberData) {
      return (
        <>
          <span
            style={{
              fontWeight: '400',
              fontSize: '10px',
              lineHeight: '12px',
              color: '#CED0D6',
            }}
          >
            {memberData.roles}
          </span>
          <br />
          <span
            style={{
              fontFamily: 'Montserrat',
              fontWeight: '600',
              fontSize: '11px',
              lineHeight: '14px',
              color: '#4C5263',
            }}
          >
            {memberData.name}
          </span>
        </>
      );
    }
    return null;
  };

  return (
    <>
      { summary && members.length ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableContainer className={classes.timesheetTable}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.styleFontSize}>
                    <TableCell className={`${classes.styleAlignLeft}`} width="150px">{project.name}</TableCell>
                    <TableCell colSpan={6} width="700px">
                      {`${moment(`${date.month + 1}`, 'MM').format('MMMM')} 
                      ${date.year} (${((total.factBillable / total.planBillable) * 100).toFixed(2)} %)`}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ textTransform: 'uppercase' }} className={classes.styleCellAndBorderColorDark}>
                    <TableCell />
                    <TableCell>
                      Plan
                    </TableCell>
                    <TableCell colSpan={2}>
                      Fact
                    </TableCell>
                    <TableCell>
                      Plan
                    </TableCell>
                    <TableCell colSpan={2}>
                      Fact
                    </TableCell>
                  </TableRow>
                  <TableRow className={`${classes.styleCellAndBorderColorDark} ${classes.styleBorderBottom}`}>
                    <TableCell className={classes.styleAlignLeft}>
                      Member
                    </TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell>
                      <i className={classes.separator} />
                      Overtime
                    </TableCell>
                    <TableCell>Costs, USD</TableCell>
                    <TableCell>Costs, USD</TableCell>
                    <TableCell>
                      <i className={classes.separator} />
                      Overtime, USD
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  { members.map((member) => (
                    <TableRow className={classes.styleCellHours} key={member.userId._id}>
                      { total[member.userId._id] ? (
                        <>
                          <TableCell
                            className={`${classes.styleAlignLeft} ${classes.styleLineHeight}`}
                          >
                            {formatMember(member.userId._id)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(total[member.userId._id].planBillable)}
                          </TableCell>

                          <TableCell>
                            {formatNumber(total[member.userId._id].factBillable)}
                          </TableCell>

                          <TableCell>
                            {formatNumber(total[member.userId._id].overtimeHours)}
                          </TableCell>

                          <TableCell>
                            { total[member.userId._id].planCosts}
                          </TableCell>

                          <TableCell>
                            {total[member.userId._id].factCosts}
                          </TableCell>

                          <TableCell>
                            {total[member.userId._id].factOvertimeCosts}
                          </TableCell>
                        </>
                      ) : (<TableCell colSpan={8}>&nbsp;</TableCell>)}
                    </TableRow>
                  ))}
                  <TableRow className={classes.styleCellHoursAndBorderColorDark}>
                    <TableCell
                      className={classes.styleAlignLeft}
                      style={{
                        fontWeight: '600',
                        fontSize: '11px',
                        lineHeight: '13px',
                        color: '#4C5263',
                      }}
                    >
                      Total
                    </TableCell>
                    <TableCell>{total.planBillable}</TableCell>
                    <TableCell>{total.factBillable}</TableCell>
                    <TableCell>{total.overtime}</TableCell>
                    <TableCell>{total.planCosts}</TableCell>
                    <TableCell>{total.factCosts}</TableCell>
                    <TableCell>{total.factOvertimeCosts}</TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      )
        : null}

    </>
  );
}

export const API_URL = '/api/';
export const USER_URL = '/api/users';
export const PROJECT_URL = '/api/projects';
export const LOGIN_URL = '/api/login';
export const LOGOUT_URL = '/api/logout';
export const LOCALE_URL = '/api/locales';
export const INVOICE_URL = '/api/invoices';
export const CURRENCY_EXCHANGE_RATES_URL = '/api/currency-exchange-rates';
export const CLIENT_CONTRACT_URL = '/api/client-contracts';
export const DASHBOARD_METRICS_URL = '/api/dashboard/metrics';
export const JIRA_URL = '/api/jira';
export const SUMMARY_PROFIT_URL = '/api/summary-profit';
export const ROLE_URL = '/api/roles';

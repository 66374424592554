import React from 'react';

import { Route, Switch } from 'react-router-dom';
import InvoiceList from './InvoiceList';
import InvoiceDetails from './InvoiceDetails';

export default function Invoices() {
  return (
    <Switch>
      <Route key="/invoices/:key" path="/invoices/:key" component={InvoiceDetails} />
      <Route key="/invoices" path="/invoices" component={InvoiceList} />
    </Switch>
  );
}

import React, { useState, useEffect, useContext } from 'react';

import {
  formatInvoiceDebtReason,
  formatInvoiceDateRange,
} from 'services/invoice';

import {
  formatUserName,
} from 'services/user';

import {
  formatDate,
  formatDateTime,
} from 'services/date';

import {
  formatMoney,
} from 'services/finance';

import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Button from 'components/CustomButtons/Button';

import { ConsoleContext } from 'services/context';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';

export default function InvoiceDebtLogsDialog({
  open, invoice, debtItem, users, onClose,
}) {
  const { t } = useContext(ConsoleContext);

  const [openDialog, setOpenDialog] = useState(open);

  const [rows, setRows] = useState([]);

  const columns = [
    { key: 'date', label: t('Date') },
    { key: 'hoursDiff', label: t('Hours Change') },
    { key: 'amountDiff', label: `${t('Amount Change')}, ${invoice.currency}` },
    { key: 'reason', label: t('Reason') },
  ];

  useEffect(() => {
    setOpenDialog(open);

    if (open) {
      (async () => {
        const rows = [];

        if (debtItem.dailyInfo) {
          debtItem.dailyInfo.forEach((item, index) => {
            const {
              date, hours, amount, changes,
            } = item;

            if (!hours && !amount && !(changes && changes.length)) {
              return;
            }

            rows.push({
              id: index,
              date: formatDate(date),
              hoursDiff: hours ? formatDiff(hours) : '-',
              amountDiff: amount ? formatDiff(formatMoney(amount, false)) : '-',
              reason: formatReason(changes, users),
            });
          });
        }

        setRows(rows);
      })();
    }
  }, [open]);

  const formatDiff = (diff) => {
    const positive = diff > 0;
    const color = positive ? 'green' : 'red';

    return (<span style={{ color }}>{ positive ? `+${diff}` : diff }</span>);
  };

  const formatReason = (changes, users) => (
    <>
      { changes.map((change, index) => (
        <div key={`change-${index}`}>
          <strong>{ t(formatInvoiceDebtReason(change.reason)) }</strong>
          ,&nbsp;
          { formatDateTime(change.date) }
          ,&nbsp;
          { formatUser(change.requestedBy, users) }
        </div>
      )) }
    </>
  );

  const formatUser = (userId, users) => {
    if (!userId) {
      return 'ERP';
    }

    return formatUserName(users.find((user) => user._id === userId));
  };

  const handleCloseDialog = () => {
    onClose();
  };

  if (!debtItem) {
    return null;
  }

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={openDialog}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        {t('Debt Details')}
        .&nbsp;
        { formatUser(debtItem.userId, users) }
        .&nbsp;
        { formatInvoiceDateRange(debtItem.startAt, debtItem.endAt) }
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    { columns.map((column) => (
                      <TableCell key={column.key} width={column.key === 'reason' ? '500px' : 'auto'}>
                        {column.label}
                      </TableCell>
                    )) }
                  </TableRow>
                </TableHead>
                <TableBody>
                  { rows.length ? rows.map((row) => (
                    <TableRow key={row.id}>
                      {columns.map((column) => (
                        <TableCell key={column.key}>
                          { row[column.key] }
                        </TableCell>
                      ))}
                    </TableRow>
                  )) : (
                    <TableRow>
                      <TableCell colSpan={columns.length}>
                        {t('No data')}
                        {' '}
                        :(
                      </TableCell>
                    </TableRow>
                  ) }
                </TableBody>
              </Table>
            </TableContainer>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

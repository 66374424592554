export function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function snakeToCamelCase(text) {
  return text.split('_').map((item, index) => (index > 0 ? capitalizeFirstLetter(item) : item)).join('');
}

export function formatOptionItem(value, options) {
  if (!value) {
    return '-';
  }

  const option = options.find((item) => item.id === value);

  return option.name;
}

import React from 'react';

import {
  getExportProjectLogsLink,
} from 'services/project';

// core components
import Button from 'components/CustomButtons/Button';

export default function ProjectLogsExport({ project, date }) {
  return (
    <Button
      size="sm"
      href={getExportProjectLogsLink(project._id, date.year, date.month)}
    >
      Download
    </Button>
  );
}

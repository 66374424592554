import React, { useState, useEffect } from 'react';

import {
  formatDate,
  formatHours,
} from 'services/date';

// core components
import { withStyles } from '@material-ui/core/styles';
import Button from 'components/CustomButtons/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from 'components/CustomDialog/CustomDialog';
import Tooltip from '@material-ui/core/Tooltip';
import {
  getProjectLogs,
  getProjectOvertime,
} from 'services/project';

export default function ProjectLogsDialog({
  open,
  projectId,
  userId,
  date,
  overtimeValue,
  formatMemberName,
  onClose,
}) {
  const [openLogsDialog, setOpenLogsDialog] = useState(false);
  const [logs, setLogs] = useState([]);
  const [overtime, setOvertime] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpenLogsDialog(false);
      return;
    }

    setLoading(true);
    setOpenLogsDialog(true);

    (async () => {
      const logs = await getProjectLogs(projectId, { userId, workedAt: date });

      let overtime = [];
      if (overtimeValue) {
        overtime = await getProjectOvertime(projectId, { userId, date });
      }

      setLogs(logs);
      setOvertime(overtime);
      setLoading(false);
    })();
  }, [open, projectId, userId, date]);

  const handleCloseLogsDialog = () => {
    onClose();
  };

  const IssueTooltip = withStyles(() => ({
    tooltip: {
      fontSize: 13,
    },
  }))(Tooltip);

  return (
    <>
      <Dialog
        loading={loading}
        maxWidth="sm"
        fullWidth
        open={openLogsDialog}
        onClose={handleCloseLogsDialog}
      >
        <DialogTitle>
          {formatMemberName({ userId })}
          {' '}
          {formatDate(date)}

        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell width="60px">Hours</TableCell>
                      <TableCell>Comment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { logs.map((log) => (
                      <TableRow key={log._id}>
                        <TableCell>{formatHours(log.timeSpentSeconds)}</TableCell>
                        <TableCell>
                          {log.jiraIssueKey ? (
                            <>
                              <IssueTooltip title={log.jiraIssueTitle} arrow>
                                <a href={log.jiraIssueLink} target="_blank" rel="noreferrer">{log.jiraIssueKey}</a>
                              </IssueTooltip>
                              {log.comment ? ': ' : null}
                            </>
                          ) : null}
                          {log.comment}
                        </TableCell>

                      </TableRow>
                    )) }
                  </TableBody>
                </Table>
              </TableContainer>

              { overtime.length ? (
                <>
                  <br />
                  <GridContainer>
                    <GridItem>Overtime</GridItem>
                  </GridContainer>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell width="60px">Hours</TableCell>
                          <TableCell width="90px">Rate</TableCell>
                          <TableCell>Comment</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { overtime.map((item) => (
                          <TableRow key={item._id}>
                            <TableCell>{formatHours(item.timeSpentSeconds)}</TableCell>
                            <TableCell>{item.rateByClient}</TableCell>
                            <TableCell>{item.comment}</TableCell>
                            <TableCell style={{ whiteSpace: 'nowrap' }} />
                          </TableRow>
                        )) }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : null }
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleCloseLogsDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useContext } from 'react';

// @material-ui/core components

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { ConsoleContext } from 'services/context';

import ClientInvoiceReport from './components/InvoiceReport';

export default function InvoiceList() {
  const { t, showPageTitle } = useContext(ConsoleContext);
  showPageTitle('Invoices');
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="success">
            <h4>{t('Billing Report')}</h4>
          </CardHeader>
          <CardBody>
            <ClientInvoiceReport />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

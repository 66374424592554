import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components

import {
  getInvoiceByKey,
} from 'services/invoice';

import {
  formatDate,
  formatDateTime,
} from 'services/date';

import {
  formatMoney,
} from 'services/finance';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';

import { ConsoleContext } from 'services/context';

import ArrowButton from 'components/ArrowButton/ArrowButton';
import InvoiceStatus from './components/InvoiceStatus';
import InvoicePaymentDetails from './components/InvoicePaymentDetails';
import ExchangeRates from './components/ExchangeRates';

export default function ClientInvoiceDetails({ match: { params: { key } } }) {
  const {
    showError, showLoader, t,
  } = useContext(ConsoleContext);

  const [invoice, setInvoice] = useState();

  const {
    sessionUser,
  } = useContext(ConsoleContext);

  const fields = [
    { id: 'key', label: 'Invoice Number' },
    { id: 'billingStartAt', label: 'Start Date' },
    { id: 'billingEndAt', label: 'End Date' },
    { id: 'currency', label: 'Currency' },
    { id: 'billingPeriodAmount', label: 'Billing Period Amount' },
    { id: 'lastInvoiceDebt', label: 'Previous Payment Debt' },
    { id: 'discount', label: 'Discount' },
    { id: 'additionalAmount', label: 'Additional Amount' },
    { id: 'serviceCompensation', label: 'Service Compensation' },
    { id: 'totalAmount', label: 'Total Amount' },
    { id: 'payedAmount', label: 'Payed Amount' },
  ];

  const statusFields = [
    { id: 'status', label: 'Status' },
    { id: 'sentAt', label: 'Created Date' },
    { id: 'completedAt', label: 'Paid Date' },
  ];

  useEffect(() => {
    (async () => {
      showLoader(true);
      try {
        let invoice;

        if (key) {
          invoice = await getInvoiceByKey(key);
        }

        setInvoice(invoice);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    })();
  }, [key]);

  const renderField = (invoice, field) => {
    let value = invoice[field];

    switch (field) {
      case 'status':
        return (<InvoiceStatus status={invoice.status} />);

      case 'billingStartAt':
      case 'billingEndAt':
        return formatDate(value);

      case 'sentAt':
      case 'completedAt':
        return formatDateTime(value);

      case 'totalAmount':
      case 'lastInvoiceDebt':
      case 'discount':
      case 'additionalAmount':
      case 'serviceCompensation':
      case 'payedAmount':
      case 'billingPeriodAmount':
        if (!value && value !== 0) {
          return '-';
        }

        if (value < 0) {
          value = (<span style={{ color: 'red' }}>{formatMoney(value, invoice.currency)}</span>);
        } else {
          value = formatMoney(value, invoice.currency);
        }

        return value;

      case 'currency':
        return (
          <>
            {value}
            <br />
            {' '}
            { renderCurrencyExchangeInfo(invoice)}
          </>
        );

      default:
        return value;
    }
  };

  const renderCurrencyExchangeInfo = (invoice) => (
    <ExchangeRates
      baseCurrency={invoice.currency}
      currencyExchangeDate={invoice.currencyExchangeDate}
      currencyExchangeRates={invoice.currencyExchangeRates}
    />
  );

  return (
    <>
      { sessionUser.id && invoice ? (
        <GridContainer>
          <GridItem>
            <ArrowButton to="/invoices">Back to invoices</ArrowButton>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="success">
                <h4>{`Invoice ${key}`}</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>{t('Summary')}</h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              { fields.map((field) => (
                                <TableRow key={field.id}>
                                  <TableCell><strong>{ t(field.label) }</strong></TableCell>
                                  <TableCell>{ renderField(invoice, field.id) }</TableCell>
                                </TableRow>
                              )) }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </GridItem>
                    </GridContainer>

                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <h4>{t('Status')}</h4>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TableContainer>
                          <Table>
                            <TableBody>
                              { statusFields.map((field) => (
                                <TableRow key={field.id}>
                                  <TableCell><strong>{ t(field.label) }</strong></TableCell>
                                  <TableCell>{ renderField(invoice, field.id) }</TableCell>
                                </TableRow>
                              )) }
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </GridItem>
                    </GridContainer>
                  </GridItem>

                  <GridItem xs={12} sm={12} md={12}>
                    <InvoicePaymentDetails invoice={invoice} client={sessionUser} />
                  </GridItem>

                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      ) : null }
    </>
  );
}

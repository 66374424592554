import React, { useState, useEffect, useContext } from 'react';

import { getProjects } from 'services/project';

import getClientContracts from 'services/clientContract';

import {
  formatDate,
} from 'services/date';

import {
  formatMoney,
  formatContractType,
} from 'services/finance';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { ConsoleContext } from 'services/context';

export default function ClientContractDetails() {
  const {
    showError, showLoader, t, sessionUser, showPageTitle,
  } = useContext(ConsoleContext);

  const clientId = sessionUser.id;

  const [client, setClient] = useState();

  const [projects, setProjects] = useState([]);

  const [contracts, setContracts] = useState({});

  const colorTitle = ['success', 'warning', 'danger', 'info', 'primary', 'rose'];

  showPageTitle('Contracts');

  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        const client = sessionUser;

        const projects = await getProjects();

        const clientContracts = await getClientContracts();

        const contracts = {};

        projects.forEach((project) => {
          contracts[project._id] = {};

          project.members.forEach((member) => {
            const memberId = member.userId._id;
            contracts[project._id][memberId] = clientContracts.filter(
              (contract) => contract.projectId === project._id && contract.userId === memberId,
            );
          });
        });

        setContracts(contracts);
        setProjects(projects);
        setClient(client);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, [clientId]);

  const formatRate = (contract, type) => (contract[type] ? formatMoney(contract[type], contract.currency) : '-');

  const projectContracts = {};
  projects.forEach((project) => {
    project.members.forEach((member) => {
      if (contracts[project._id][member.userId._id].length) {
        projectContracts[project._id] = true;
      }
    });
  });

  return (
    <div>
      <GridContainer>
        { client ? (
          <>
            { projects.length
              ? projects.map((project, index) => (
                <>
                  { projectContracts[project._id] ? (
                    <GridItem xs={12} sm={12} md={12} key={project._id}>
                      <Card>
                        <CardHeader color={colorTitle[index] ? colorTitle[index] : colorTitle[0]}>
                          <h4>{t('Project {{projectName}}', { projectName: project.name })}</h4>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              { project.members.map((member) => (
                                <GridContainer key={member._id}>
                                  { contracts[project._id][member.userId._id].length ? (
                                    <>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <h4>
                                          {member.userId.firstName}
                                          {' '}
                                          {member.userId.lastName}
                                        </h4>
                                      </GridItem>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <TableContainer>
                                          <Table>
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>{t('Start Date')}</TableCell>
                                                <TableCell>{t('End Date')}</TableCell>
                                                <TableCell>{t('Contract Type')}</TableCell>
                                                <TableCell>{t('Hourly Rate')}</TableCell>
                                                <TableCell>{t('Monthly Rate')}</TableCell>
                                                <TableCell>{t('Hours/Day')}</TableCell>
                                                <TableCell>{t('Prepayment')}</TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {contracts[project._id][member.userId._id].map((contract) => (
                                                <TableRow key={contract._id}>
                                                  <TableCell>
                                                    {formatDate(contract.startAt)}
                                                  </TableCell>
                                                  <TableCell>
                                                    {formatDate(contract.endAt)}
                                                  </TableCell>
                                                  <TableCell>
                                                    {t(formatContractType(contract.type))}
                                                  </TableCell>
                                                  <TableCell>
                                                    {formatRate(contract, 'hourlyRate')}
                                                  </TableCell>
                                                  <TableCell>
                                                    {formatRate(contract, 'monthlyRate')}
                                                  </TableCell>
                                                  <TableCell>
                                                    {contract.dailyHours || '8'}
                                                  </TableCell>
                                                  <TableCell>
                                                    {t(contract.excludeFromPrePayment ? 'No' : 'Yes')}
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </GridItem>
                                    </>
                                  )
                                    : null }
                                </GridContainer>
                              ))}
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                    </GridItem>
                  ) : null}
                </>
              )) : (
                <GridItem xs={12} sm={12} md={12}>
                  <Card>
                    <CardBody>
                      { t('No projects') }
                    </CardBody>
                  </Card>
                </GridItem>
              )}
          </>
        ) : null }
      </GridContainer>
    </div>
  );
}

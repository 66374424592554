import {
  ROLE_URL,
} from '../utils/apiRouting';

import {
  get, formatQuery,
} from '../utils/http';

export default async function getRoles(query = {}) {
  return get(ROLE_URL + formatQuery(query));
}

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import {
  getInvoiceReport,
} from 'services/invoice';

import {
  formatMoney,

  getDefaultCurrency,
} from 'services/finance';

import {
  formatDate,
} from 'services/date';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

import { ConsoleContext } from 'services/context';

import TableReport from 'components/TableReport/TableReport';
import InvoiceStatus from './InvoiceStatus';

export default function ClientInvoiceList() {
  const { showError, t, showLoader } = useContext(ConsoleContext);

  const [rows, setRows] = useState([]);

  const currency = getDefaultCurrency();

  const columns = [
    { id: 'key', label: t('Invoice Number') },
    { id: 'billingStartAt', label: t('Start Date') },
    { id: 'billingEndAt', label: t('End Date') },
    { id: 'serviceCompensation', label: `${t('Service Compensation')}, ${currency}` },
    { id: 'discount', label: `${t('Discount')}, ${currency}` },
    { id: 'additionalAmount', label: `${t('Additional Amount')}, ${currency}` },
    { id: 'lastInvoiceDebt', label: `${t('Previous Payment Debt')}, ${currency}` },
    { id: 'billingPeriodAmount', label: `${t('Billing Period Amount')}, ${currency}` },
    { id: 'totalAmount', label: `${t('Total Amount')}, ${currency}` },
    { id: 'payedAmount', label: `${t('Payed Amount')}, ${currency}` },
    { id: 'status', label: `${t('Status')}` },
  ];

  const renderStatus = (status) => (<InvoiceStatus status={status} />);

  useEffect(() => {
    (async () => {
      showLoader(true);
      try {
        const report = await getInvoiceReport();

        const rows = report.invoices.map((invoice) => (
          {
            id: invoice._id,
            key: (<Link to={`/invoices/${invoice.key}`}>{invoice.key}</Link>),
            billingStartAt: formatDate(invoice.billingStartAt),
            billingEndAt: formatDate(invoice.billingEndAt),
            serviceCompensation: formatMoney(invoice.serviceCompensation, false),
            discount: formatMoney(invoice.discount, false),
            additionalAmount: formatMoney(invoice.additionalAmount, false),
            lastInvoiceDebt: formatMoney(invoice.lastInvoiceDebt, false),
            billingPeriodAmount: formatMoney(invoice.billingPeriodAmount, false),
            totalAmount: formatMoney(invoice.totalAmount, false),
            payedAmount: (invoice.payedAmount || invoice.payedAmount === 0) ? formatMoney(invoice.payedAmount, false) : '-',
            status: renderStatus(invoice.status),
          }
        ));

        setRows(rows);
      } catch (error) {
        showError(error);
      }
      showLoader(false);
    })();
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <TableReport
          columns={columns}
          rows={rows}
        />

      </GridItem>
    </GridContainer>
  );
}

import moment from 'moment';

import {
  INVOICE_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';
import { formatOptionItem } from './text';

function getStatusOptions() {
  return [
    { id: 'sent', name: 'Pending' },
    { id: 'completed', name: 'Paid' },
  ];
}

function getInvoiceDebtReasonOptions() {
  return [
    { id: 'unknown', name: 'Unknown' },
    { id: 'log_change', name: 'Log changed' },
    { id: 'overtime_change', name: 'Overtime changed' },
    { id: 'include_not_prepayment', name: 'Was not included in previous invoice prepayment' },
    { id: 'rate_change', name: 'Rate changed' },
    { id: 'allocation_change', name: 'Allocation plan changed' },
    { id: 'custom_date_change', name: 'Work calendar changed' },
    { id: 'user_custom_date_change', name: 'Partner work calendar changed' },
  ];
}

export const formatStatus = (id) => formatOptionItem(id, getStatusOptions());

export function formatInvoiceDebtReason(id) {
  return formatOptionItem(id, getInvoiceDebtReasonOptions());
}

export async function getInvoiceByKey(key) {
  return get(`${INVOICE_URL}/${key}`);
}

export function formatInvoiceDateRange(startAt, endAt) {
  const startDate = moment.utc(startAt);
  const endDate = moment.utc(endAt);

  let range = '';

  if (startDate.isSame(endDate, 'year')) {
    if (startDate.isSame(endDate, 'month')) {
      range += startDate.format('D');
    } else {
      range += startDate.format('Do of MMMM');
    }
  } else {
    range += startDate.format('Do of MMMM YYYY');
  }

  range += ` - ${endDate.format('Do of MMMM YYYY')}`;

  return range;
}

export async function getInvoiceReport() {
  return get(`${INVOICE_URL}/client-report`);
}

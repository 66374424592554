const completedFetch = (url, options) => fetch(url, options)
  .then((res) => {
    if (res.ok) {
      return res.json();
    }

    return Promise.reject(res);
  })
  .catch((res) => {
    if ((res.status === 401 || res.status === 409) && window.location.pathname !== '/login') {
      // handle
    }

    return (res.name === 'AbortError') ? Promise.reject() : res.json().then((message) => Promise.reject(message));
  });

export const get = (url, controller) => {
  const options = {
    method: 'GET',
    headers: {},
  };

  if (controller) options.signal = controller.signal;

  return completedFetch(url, options);
};

export const post = (url, body, controller) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  if (controller) options.signal = controller.signal;

  return completedFetch(url, options);
};

export const put = (url, body) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  return completedFetch(url, options);
};

export const httpDelete = (url) => {
  const options = {
    method: 'DELETE',
    headers: {},
  };

  return completedFetch(url, options);
};

export function uploadFile(url, file) {
  const formData = new FormData();
  formData.append('file', file);

  const options = {
    method: 'POST',
    headers: {},
    body: formData,
  };

  return completedFetch(url, options);
}

export function formatQuery(query) {
  if (!query) {
    return '';
  }

  const params = Object.keys(query).map((key) => {
    let value = query[key];

    if (Array.isArray(value)) {
      value = value.join(',');
    }

    if (typeof value === 'boolean') {
      value = value ? '1' : '';
    }

    return `${key}=${encodeURIComponent(value)}`;
  });

  if (!params.length) {
    return '';
  }

  return `?${params.join('&')}`;
}

export function formatJsonQuery(query) {
  if (!query) {
    return '';
  }

  return `?_json=${encodeURIComponent(JSON.stringify(query))}`;
}

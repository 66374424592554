import {
  PROJECT_URL,
} from '../utils/apiRouting';

import {
  formatQuery,
  get,
} from '../utils/http';
import { formatServerDate } from './date';

import { getServerHost } from './session';

export async function getProjects() {
  return get(PROJECT_URL);
}

export async function getProjectMonthlySummary(projectId, year, month) {
  return get(`${PROJECT_URL}/${projectId}/summary-monthly/${year}/${month}`);
}

export function getExportProjectLogsLink(projectId, year, month) {
  return `${getServerHost() + PROJECT_URL}/${projectId}/export-logs/${year}/${month}`;
}

// Logs
export async function getProjectLogs(projectId, query) {
  if (query.workedAt) {
    query.workedAt = formatServerDate(query.workedAt);
  }

  return get(`${PROJECT_URL}/${projectId}/logs/${formatQuery(query)}`);
}

// Overtime
export async function getProjectOvertime(projectId, query) {
  if (query.date) {
    query.date = formatServerDate(query.date);
  }

  return get(`${PROJECT_URL}/${projectId}/overtime/${formatQuery(query)}`);
}

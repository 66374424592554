import {
  DASHBOARD_METRICS_URL,
} from '../utils/apiRouting';

import {
  get,
} from '../utils/http';

export default async function getDashboardMetrics(year, month) {
  return get(`${DASHBOARD_METRICS_URL}/${year}/${month}`);
}

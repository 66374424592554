import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components

import { getUsers } from 'services/user';

// core components
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';

import { ConsoleContext } from 'services/context';
import { getProjects } from '../../services/project';
import MonthPicker from '../../components/MonthPicker/MonthPicker';
import ProjectLogs from './components/ProjectLogs';

export default function WorkCalendar() {
  useEffect(() => {
    async function fetchData() {
      showLoader(true);

      try {
        const projects = await getProjects();
        const users = await getUsers();
        setUsers(users);
        setProjects(projects);
      } catch (error) {
        showError(error);
      }

      showLoader(false);
    }

    fetchData();
  }, []);

  const {
    showError, showLoader, getSessionStorage, showPageTitle,
  } = useContext(ConsoleContext);

  const [projects, setProjects] = useState([]);

  const [users, setUsers] = useState([]);

  const [date, setDate] = useState({
    year: 0,
    month: 0,
  });

  const storage = getSessionStorage();

  showPageTitle('Work Calendar');

  const formatUserName = (user) => {
    if (!user) {
      return '-';
    }

    return `${user.firstName} ${user.lastName}`;
  };

  function formatMemberName(member) {
    const user = users.find((user) => user._id === member.userId._id || user._id === member.userId);

    return formatUserName(user);
  }

  function getSavedMembers(project) {
    return project.members.filter((member) => member._id && !member.disabled);
  }

  const handleMonthChange = async ({ year, month }) => {
    setDate({ year, month });
  };

  return (

    <>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="success">
              <h4>Time Report</h4>
            </CardHeader>

            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  <MonthPicker
                    storage={storage}
                    onChange={handleMonthChange}
                  />
                </GridItem>
              </GridContainer>
              { projects.length ? (
                projects.map((project) => (
                  <div key={project._id.toString()}>
                    <ProjectLogs
                      project={project}
                      /* eslint-disable-next-line react/jsx-no-bind */
                      formatMemberName={formatMemberName}
                      members={getSavedMembers(project)}
                      date={date}
                    />
                  </div>
                ))

              ) : null }
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </>

  );
}

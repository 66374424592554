import React, { useState, useEffect, useContext } from 'react';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

import getLastSyncJob from 'services/jira';

import {
  formatDateTime,
} from 'services/date';

// core components

import { ConsoleContext } from 'services/context';

const useStyles = makeStyles(() => ({
  infoText: {
    marginRight: '10px',
    display: 'inline-block',
    backgroundColor: 'inherit',
    color: '#8F97AE',
    fontWeight: '400',
  },
}));

export default function LogsSync({ year, month }) {
  const classes = useStyles();

  const {
    showError,
  } = useContext(ConsoleContext);

  const [lastSyncJob, setLastSyncJob] = useState();

  useEffect(() => {
    if (!year) {
      return;
    }

    (async () => {
      try {
        const lastSyncJob = await getLastSyncJob(year, month);

        setLastSyncJob(lastSyncJob);
      } catch (e) {
        showError(e);
      }
    })();
  }, [year, month]);

  const renderLastRun = (lastSyncJob) => {
    if (!lastSyncJob) {
      return 'No info';
    }

    return formatDateTime(lastSyncJob.lastFinishedAt);
  };

  return (
    <div className={classes.infoText}>
      Last Sync:
      {' '}
      { renderLastRun(lastSyncJob) }
    </div>
  );
}
